import { useState, createContext, useContext } from "react";
import React from "react";
import {CodesContext} from "./index";
function Inputter(props) {
  let codeValue;
  const handleChange = (event) => {
    codeValue = event.target.value;
    console.log(codeValue);
  };

  return (
    <CodesContext.Consumer>
      {({ codes, setCodes }) => {
        const addCode=(e)=>{
          e.preventDefault();
          setCodes(codes.concat([codeValue]))
        }
        return (
          <>
            <div className="inputter component">
              <h2>new package</h2>
              <form className="formInput" onSubmit={e => addCode(e)}>
                <input onChange={handleChange} placeholder="tracking code" />
                <button
                  type="submit"
                style={{color:'white'}}>
                  &rarr;
                </button>
              </form>
            </div>
          </>
        );
      }}
    </CodesContext.Consumer>
  );
}

export default Inputter;
