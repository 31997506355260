import { useEffect, useState } from "react";
import BingMapsReact from "bingmaps-react";
import React from "react";
import { CurrentContext, CodesContext } from "./index";
function Panel(props) {
  return (
    <CurrentContext.Consumer>
      {({ current, setCurrent }) => {
        console.log(current);
        if (current != null) {
          return (
            <div className="panel">
              <Map current={current} />
              <h2>updates</h2>
              <div className="event-cont">
                {current["trackings"][0]["events"].map((event, index) => {
                  return (
                    <div className="event" key={index}>
                      <div className="panel-status">
                        {event["status"].toLowerCase()}
                      </div>
                      <div className="info">
                        <div className="date">
                          {new Date(event["datetime"]).toLocaleString()}
                        </div>
                        <div className="location">{event["location"]}</div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return (
            <div className="panel">
              <h2>updates</h2>
              <p className="empty-text">no package selected</p>
            </div>
          );
        }
      }}
    </CurrentContext.Consumer>
  );
}
// https://github.com/milespratt/bingmaps-react

const getLatLong = (address) => {
  return fetch(
    `https://api.openweathermap.org/geo/1.0/direct?q=${address}&limit=1&appid=70eea17c47ed3cbccc853e452c902e99`
  ).then((res) => res.json());
}

function Map(props) {
  const [location, setLocation] = useState(null);
  useEffect(() => {
    getLatLong(props.current["trackings"][0]["events"][0]["location"]).then(
      (res) => {
        setLocation(res);
        console.log(res)
      }
    );
  }, [props.current]);
  if (location != null && location.length > 0) {
    let pushPin = {
      center: {
        latitude: location[0]["lat"],
        longitude: location[0]["lon"],
      },
      options: {
        title: "Current Location",
      },
    };
    return (
      <div className="map">
        <BingMapsReact
          bingMapsKey={"Au6_tHIweKq7uy6E8i4WExUj2gaGwriltwatry83sEhdBF15U5UCpXmm01y1wzH-"}
          pushPins={[pushPin]}
          height="200px"
          width="375px"
          mapOptions={{
            showMapTypeSelector: false,
            showZoomButtons: false,
          }}
          viewOptions={{
            center: {
              latitude: location[0]["lat"],
              longitude: location[0]["lon"],
            },
            mapTypeId: "canvasDark"
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="map">
        <p className="empty-text">no location data</p>
      </div>
    );
  }
}

export default Panel;
