import React, { useState, createContext } from "react";
import ReactDOM from "react-dom/client";
import Package from "./Package";
import Panel from "./Panel";
import Inputter from "./Inputter";
import "./style.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
const CodesContext = React.createContext([]);
const CurrentContext = React.createContext([]);

function App() {
  const [codes, setCodes] = useState(localStorage.getItem("userCodes") ? JSON.parse(localStorage.getItem("userCodes")) : []);
  console.log(codes);
  const [current, setCurrent] = useState();
  let packageList = [];
  [...new Set(codes)].forEach((code, index) => {
    let p = <Package tID={code} />;
    if (p!=null) {
      packageList.push(p);
      localStorage.setItem(
        "userCodes",
       JSON.stringify(codes)
      );
      console.log(JSON.stringify(codes))
    }
  });
  const giveList = () => {
    if(packageList.length>0){
      return packageList;
    } else {
      return <p className="empty-text">add a package above</p>
    }
  }
  return (
    <CurrentContext.Provider value={{ current, setCurrent }}>
    <CodesContext.Provider value={{ codes, setCodes }}>
      <h1>packrapp</h1>
      <div className="content-cont">
      <div className="left">
        <Inputter />
        <div className="component h400">
          <h2>your packages</h2>
          <div className="pkgContainer">{giveList()}</div>
        </div>
      </div>
      <div className="right"><Panel/></div>
      </div>
      <footer>packrapp © 2022</footer>
    </CodesContext.Provider>
    </CurrentContext.Provider>
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export {CodesContext, CurrentContext};

