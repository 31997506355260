import { useEffect, useState } from "react";
import React from "react";
import { CurrentContext, CodesContext } from "./index";
const getTrack = async (tID) => {
  console.log(tID + " code");
  let res = await fetch("https://api.ship24.com/public/v1/trackers/track", {
    method: "POST",
    headers: {
      Authorization: "Bearer apik_oe3RJCDWXkzrzH4stXNrXGxI9luH6n",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      trackingNumber: tID,
    }),
    redirect: "follow",
  });
  if (res.ok) {
    let data = await res.json();
    return data ? data["data"] : null;
  } else {
    console.log("invalid a");
    return null;
  }
};
const options = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
};
function Package(props) {
  const [data, setData] = useState(null);
  useEffect(() => {
    getTrack(props.tID).then((res) => {
      setData(res);
    });
  }, [props.tID]);

  console.log(data);
  if (data != null) {
    return (
      <CodesContext.Consumer>
        {({ codes, setCodes }) => {
          return (
            <CurrentContext.Consumer>
              {({ current, setCurrent }) => {
                const remove = () => {
                  setCodes(codes.filter((code) => code !== props.tID));
                  // if current package is removed, set current to null to hide panel info
                  localStorage.setItem(
                    "userCodes",
                    JSON.stringify(codes.filter((code) => code !== props.tID))
                  );
                  if (
                    current["trackings"][0]["events"][0]["trackingNumber"] ===
                    props.tID
                  ) {
                    setCurrent(null);
                  }
                };
                function handleClick(e) {
                  setCurrent(data);
                }
                return (
                  <div className="pkgOuter">
                    <div
                      className="pkgModule"
                      id={props.tID}
                      onClick={handleClick}
                    >
                      <div>
                        <div className="status">
                          {data &&
                            data["trackings"][0]["events"][0]["status"]
                              .toLowerCase()
                              .substring(0, 45) + "..."}
                        </div>
                        <div className="">
                          {data &&
                            new Date(
                              data["trackings"][0]["events"][0]["datetime"]
                            ).toLocaleDateString("en-us", options)}
                        </div>
                        <div className="">
                          {data &&
                            (data["trackings"][0]["shipment"]["delivery"][
                              "estimatedDeliveryDate"
                            ]
                              ? new Date(
                                  data["trackings"][0]["shipment"]["delivery"][
                                    "estimatedDeliveryDate"
                                  ]
                                )
                                  .toLocaleString()
                                  .toLowerCase()
                              : null)}
                        </div>
                      </div>
                      <div className="courierInfo">
                        <img
                          src={`${data &&
                            data["trackings"][0]["events"][0][
                              "courierCode"
                            ]}.png`}
                        />
                        <div className="">
                          {data &&
                            data["trackings"][0]["events"][0][
                              "courierCode"
                            ].toUpperCase()}
                        </div>
                      </div>
                    </div>
                    <button onClick={remove} className="remove-button">
                      x
                    </button>
                  </div>
                );
              }}
            </CurrentContext.Consumer>
          );
        }}
      </CodesContext.Consumer>
    );
  }
}

export default Package;
